import React from 'react';
import styled from 'styled-components';
import Handlebars from 'handlebars';

const TextAreaElement = ({ name, data, font, fontFamily, alignment, zoom }) => {
  var text = name;

  const extractParams = str => {
    if (!str) return [];
    const words = [];
    for (let i = 0; i < str.length; i++) {
      if (str.charAt(i) === '{' && str.charAt(i - 1) === '{') {
        const stopIndex = str.indexOf('}}', i);
        if (stopIndex !== -1) words.push(str.substring(i + 1, stopIndex));
      }
    }
    return words;
  };

  const params = data || extractParams(name).reduce((acc, curr) => ((acc[curr] = 'xx'), acc), {});

  if (params) {
    for (const [key, value] of Object.entries(params)) {
      text = text.replace(`{{${key}}}`, value);
    }
  }

  return (
    <HeaderWrapper
      className="flex mt-2 regular-text w-full"
      style={{
        fontSize: `${((font?.size || 14) * zoom) / 100}px`,
        color: `${font.color || '#16192C'}`,
        fontFamily: `${font.name || 'Poppins'}`,
        lineHeight: `${font.line_height || 1.4}`,
        ...fontFamily,
        ...alignment,
      }}>
      {text}
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled('label')`
  white-space: pre-wrap;
`;

export default TextAreaElement;

import React, { useEffect, useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import styled from 'styled-components';
import Button from '../button/button';

const PdfPage = ({
  name,
  is_required,
  onChange = () => {},
  font = null,
  fontFamily,
  showRequiredError,
  signatureValue = null,
  sub_label = null,
  pageWidth,
  zoom,
  width,
  oneLine,
}) => {
  const signRef = useRef();
  const containerRef = useRef();

  const [canvasWidth, setCanvasWidth] = useState(300);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);

  useEffect(() => {
    if (containerRef?.current) {
      setCanvasWidth(containerRef?.current.getBoundingClientRect().width - 2);
    }
  }, [containerRef?.current, pageWidth]);

  useEffect(() => {
    if (signRef?.current && signatureValue && containerRef?.current) {
      const canvasWidth = containerRef?.current.getBoundingClientRect().width;
      signRef.current?.clear();
      signRef?.current.fromDataURL(signatureValue, { width: canvasWidth, height: 140 });
    }
  }, [signRef?.current]);

  return (
    <div className="flex-column" ref={containerRef}>
      <div
        style={{
          marginBottom: `${(2 * zoom) / 100}px`,
          width: width,
          minHeight: `${((font.size || 14) * zoom) / 100}px`,
        }}
        className={`flex items-center ${oneLine ? 'one-line' : ''}`}>
        <span
          className={`regular-text lighter-text ${oneLine ? 'one-line' : ''}`}
          style={{
            fontSize: `${((font.size || 14) * zoom) / 100}px`,
            color: font?.color || '#6B7280',
            ...fontFamily,
          }}>
          {name}
        </span>
        {is_required && (
          <span className="required-star ml-1" style={{ fontSize: `${((font.size || 14) * zoom) / 100}px` }}>
            *
          </span>
        )}
      </div>
      {sub_label && (
        <div className="mt-1">
          <span className="regular-text gray-500-text">{sub_label}</span>
        </div>
      )}
    </div>
  );
};

const PdfPageWrapper = styled('div')`
  .signCanvas {
    border: 1px solid ${({ theme }) => theme.colors.ZenGraySecondary};
    border-radius: 18px;
  }

  .required-error {
    border-color: ${({ theme }) => theme.colors.ZenNegative};
  }
`;

export default PdfPage;

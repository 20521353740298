import React, { useState, useEffect } from 'react';

import Address from '../../components/FormElements/address';
import Checkbox from '../../components/FormElements/checkbox';
import DateOfBirthDayMonthYear from '../../components/FormElements/date-of-birth/DayMonthYear';
import DateSelectorElement from '../../components/FormElements/date-selector';
import Dropdown from '../../components/FormElements/dropdown';
import FormPhoneInput from '../../components/FormElements/form-phone-input';
import HeaderElement from '../../components/FormElements/header';
import ImageElement from '../../components/FormElements/image-element';
import InputElement from '../../components/FormElements/input';
import Payment from '../../components/FormElements/payment';
import Signature from '../../components/FormElements/signature';
import PdfPage from '../../components/FormElements/pdf-page';
import TextAreaElement from '../../components/FormElements/textarea';
import { useDrag } from 'react-dnd';
import { ReactComponent as DeleteIcon } from '../../assets/images/trash-red.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/images/bottom-right.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { getAlignment } from '../../helpers/utils';

const WidgetComponent = ({
  component,
  index,
  showRequiredError,
  formAttributes,
  getFontFamily,
  autoFillLookUp,
  values,
  id,
  left,
  top,
  hideSourceOnDrag,
  selectComponent,
  panelId,
  widget,
  setSelectedWidget,
  selectedComponent,
  updateWidget,
  selectedPage,
  resize,
  setResize,
  updateComponent,
  zoom,
  width,
  height,
}) => {
  const style = {
    position: 'absolute',
    // border: '1px dashed gray',
    // padding: '8px',
    // cursor: 'move',
    // background: 'none',
  };
  const showRequireConfirmationError = false;
  const [size, setSize] = useState({ width: component.width, height: component.height });
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'component',
      item: { component, widget, left, top },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [component, widget, left, top],
  );
  if (isDragging && hideSourceOnDrag) {
    return <div ref={drag} />;
  }

  const [drag_, setDrag] = useState({
    active: false,
    x: '',
    y: '',
  });

  const startResize = e => {
    e.stopPropagation();
    setResize(true);
    setDrag({
      active: true,
      x: e.clientX,
      y: e.clientY,
    });
  };
  const resizeFrame = e => {
    if (!resize) return;
    const { active, x, y } = drag_;
    if (active) {
      const xDiff = Math.abs(x - e.clientX);
      const yDiff = Math.abs(y - e.clientY);
      const newW = x > e.clientX ? size.width - xDiff : size.width + xDiff;
      const newH = y > e.clientY ? size.height - yDiff : size.height + yDiff;

      setDrag({ ...drag_, x: e.clientX, y: e.clientY });
      setSize({ width: newW, height: newH });
    }
  };

  const stopResize = e => {
    if (!resize) return;
    setResize(false);
    setDrag({ ...drag_, active: false });

    updateWidget({
      ...widget,
      panelId: selectedPage.id,
      components: widget.components.map(c =>
        c.id === component.id ? { ...component, width: size.width, height: size.height } : c,
      ),
    });
    selectComponent({ ...component, width: size.width, height: size.height });
  };
  return (
    <div
      className={`flex component-hover `}
      // style={{ flex: `1 1 calc(100%/${c.length})` }}
      style={{
        ...style,
        left: left * (zoom / 100),
        top: top * (zoom / 100),
        zIndex: isDragging ? 2 : 1,
      }}
      onMouseMove={resizeFrame}
      onMouseUp={stopResize}
      onMouseDown={() => selectComponent({ ...component, width: size.width, height: size.height })}
      ref={resize ? null : drag}
      data-testid={resize ? null : 'component'}
      onClick={e => {
        e.stopPropagation();
        setSelectedWidget(widget);
        selectComponent(component);
      }}
      // onMouseDown={e => {
      //   e.stopPropagation();
      //   setSelectedWidget(widget);
      //   selectComponent(component);
      // }}
    >
      <div
        className={`flex relative ${selectedComponent?.id === component.id ? 'selected-widget' : 'widget-component'}`}
        style={{
          width: `${size?.width * (zoom / 100)}px`,
          minWidth: `${size?.width * (zoom / 100)}px`,
          maxWidth: `${size?.width * (zoom / 100)}px`,
          height: `${size?.height * (zoom / 100)}px`,
          minHeight: `${size?.height * (zoom / 100)}px`,
          maxHeight: `${size?.height * (zoom / 100)}px`,
          boxSizing: 'content-box',

          // width: `${width * (zoom / 100)}px`,
          // minWidth: `${width * (zoom / 100)}px`,
          // maxWidth: `${width * (zoom / 100)}px`,
          // height: `${height * (zoom / 100)}px`,
          // minHeight: `${height * (zoom / 100)}px`,
          // maxHeight: `${height * (zoom / 100)}px`,
        }}>
        {component.component_type === 'IMAGE' && (
          <ImageElement
            key={component.id}
            src={component?.image?.image?.url}
            alt={component.name}
            media={component?.image?.media}
            // size={getFormAttributeValue('width')}
            // width={component?.width}
            // height={component?.height}
            width={width}
            height={height}
            zoom={zoom}
          />
        )}
        {component.component_type === 'HEADER' && (
          <HeaderElement
            key={component.id}
            name={component.name}
            font={component.font}
            fontFamily={getFontFamily(component?.font?.style, component?.font?.name)}
            alignment={getAlignment(component.alignment)}
          />
        )}
        {component.component_type === 'TEXTAREA' && (
          <TextAreaElement
            key={component.id}
            name={component.component_text}
            data={component.data}
            font={component.font}
            fontFamily={getFontFamily(component?.font?.style, component?.font?.name)}
            alignment={getAlignment(component.alignment)}
            zoom={zoom}
          />
        )}
        {/* {component.component_type === 'CHOOSER' && (
          <Dropdown
            {...component}
            key={component.id}
            is_required={component.is_required}
            showRequiredError={showRequiredError && component.is_required}
            name={component.name}
            placeholder={component.label}
            fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
            is_autofill={component.is_autofill}
            sub_label={component.sub_label}
            autoFillValue={autoFillLookUp(component.lexicon)}
            options={component.list?.choices?.map(c => ({ ...c, label: c.name, value: c.id }))}
            font={formAttributes?.font}
            selectedValue={values[component.id]}
            onChange={option => {}}
          />
        )}
        {component.component_type === 'PHONE' && (
          <FormPhoneInput
            key={component.id}
            is_required={component.is_required}
            showRequiredError={showRequiredError && component.is_required}
            name={component.name}
            font={formAttributes?.font}
            fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
            is_autofill={component.is_autofill}
            autoFillValue={autoFillLookUp(component.lexicon)}
            sub_label={component.sub_label}
            phoneValue={values[component.id]}
            onChange={value => {}}
          />
        )} */}
        {(component.component_type === 'ALPHA' ||
          component.component_type === 'NUMERIC' ||
          component.component_type === 'DATE' ||
          component.component_type === 'ALPHANUMERIC') && (
          <div className="flex flex-1 move">
            <InputElement
              className="flex-column flex-1"
              key={component.id}
              is_required={component.is_required}
              showRequiredError={showRequiredError && component.is_required}
              showRequireConfirmationError={
                component.requires_confirmation &&
                showRequireConfirmationError &&
                values[component.id] !== values[`${component.id}-confirm`]
              }
              name={component.name}
              label={component.label}
              font={formAttributes?.font}
              sub_label={component.sub_label}
              fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
              is_autofill={component.is_autofill}
              autoFillValue={autoFillLookUp(component.lexicon)}
              type={component.component_type}
              value={values[component.id]}
              onChange={value => {}}
              zoom={zoom}
              inputFont={component.font}
              inputFontFamily={getFontFamily(component?.font?.style, component?.font?.name)}
              alignment={getAlignment(component.alignment)}
              width={`${size?.width * (zoom / 100)}px`}
              oneLine={true}
            />
            {component.requires_confirmation && (
              <InputElement
                className="flex-column"
                is_required={component.is_required}
                showRequiredError={showRequiredError && component.is_required}
                showRequireConfirmationError={
                  showRequireConfirmationError && values[component.id] !== values[`${component.id}-confirm`]
                }
                name={`Confirm ${component.name}`}
                label={component.label}
                font={formAttributes?.font}
                sub_label={component.sub_label}
                fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
                is_autofill={component.is_autofill}
                type={component.component_type}
                value={values[`${component.id}-confirm`]}
                onChange={value => {}}
                zoom={zoom}
                inputFont={component.font}
                inputFontFamily={getFontFamily(component?.font?.style, component?.font?.name)}
                alignment={getAlignment(component.alignment)}
              />
            )}
          </div>
        )}
        {component.component_type === 'SIGNATURE' && (
          <Signature
            key={component.id}
            is_required={component.is_required}
            showRequiredError={showRequiredError && component.is_required}
            name={component.name}
            font={formAttributes?.font}
            sub_label={component.sub_label}
            fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
            signatureValue={values[component.id]}
            onChange={value => {}}
            pageWidth={formAttributes.panel_width}
            zoom={zoom}
            width={`${size?.width * (zoom / 100)}px`}
            oneLine={true}
          />
        )}
        {component.component_type === 'PDF_PAGES' && (
          <PdfPage
            key={component.id}
            is_required={component.is_required}
            showRequiredError={showRequiredError && component.is_required}
            name={component.name}
            font={formAttributes?.font}
            sub_label={component.sub_label}
            fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
            signatureValue={values[component.id]}
            onChange={value => {}}
            pageWidth={formAttributes.panel_width}
            zoom={zoom}
            width={`${size?.width * (zoom / 100)}px`}
            oneLine={true}
          />
        )}
        {/* {component.component_type === 'DOB' && component.component_layout === 'DAYMONTHYEAR' && (
          <DateOfBirthDayMonthYear
            key={component.id}
            is_required={component.is_required}
            showRequiredError={showRequiredError && component.is_required}
            name={component.name}
            placeholder={component.label}
            font={formAttributes?.font}
            sub_label={component.sub_label}
            fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
            is_autofill={component.is_autofill}
            autoFillValue={autoFillLookUp(component.lexicon)}
            selectedDate={values[component.id]}
            setSelectedDate={value => {}}
          />
        )}
        {component.component_type === 'DOB' &&
          (component.component_layout === 'DATEPICKER' || !component.component_layout) && (
            <DateSelectorElement
              key={component.id}
              is_required={component.is_required}
              showRequiredError={showRequiredError && component.is_required}
              name={component.name}
              placeholder={component.label}
              font={formAttributes?.font}
              sub_label={component.sub_label}
              fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
              is_autofill={component.is_autofill}
              autoFillValue={autoFillLookUp(component.lexicon)}
              selectedDate={values[component.id]}
              setSelectedDate={value => {}}
            />
          )}
        {component.component_type === 'DATE' && (
          <DateSelectorElement
            key={component.id}
            is_required={component.is_required}
            showRequiredError={showRequiredError && component.is_required}
            name={component.name}
            placeholder={component.label}
            font={formAttributes?.font}
            sub_label={component.sub_label}
            fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
            is_autofill={component.is_autofill}
            autoFillValue={autoFillLookUp(component.lexicon)}
            selectedDate={values[component.id]}
            setSelectedDate={value => {}}
          />
        )}
        {component.component_type === 'MULTICHOICE' && (
          <Checkbox
            key={component.id}
            is_required={component.is_required}
            showRequiredError={showRequiredError && component.is_required}
            name={component.name}
            font={formAttributes?.font}
            fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
            is_autofill={component.is_autofill}
            autoFillValue={autoFillLookUp(component.lexicon)}
            checked={values[component.id]}
            onChange={value => {}}
          />
        )}
        {component.component_type === 'SIGNATURE' && (
          <Signature
            key={component.id}
            is_required={component.is_required}
            showRequiredError={showRequiredError && component.is_required}
            name={component.name}
            font={formAttributes?.font}
            sub_label={component.sub_label}
            fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
            signatureValue={values[component.id]}
            onChange={value => {}}
            pageWidth={formAttributes.panel_width}
          />
        )}
        {component.component_type === 'ADDRESS' && (
          <Address
            key={component.id}
            is_required={component.is_required}
            showRequiredError={showRequiredError && component.is_required}
            name={component.name}
            font={formAttributes?.font}
            sub_label={component.sub_label}
            fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
            is_autofill={component.is_autofill}
            autoFillValue={autoFillLookUp(component.lexicon)}
            placeholder={component.label}
            selectedValue={values[component.id]}
            onAddressUpdate={address => {}}
          />
        )}
        {component.component_type === 'PRODUCT' && (
          <Dropdown
            options={getProductOptions(component.products)}
            key={component.id}
            is_required={component.is_required}
            showRequiredError={showRequiredError && component.is_required}
            name={component.name}
            placeholder={component.label}
            font={formAttributes?.font}
            sub_label={component.sub_label}
            fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
            is_autofill={component.is_autofill}
            autoFillValue={autoFillLookUp(component.lexicon)}
            selectedValue={values[component.id]}
            onChange={option => {}}
            updateOnLoad={true}
            {...component}
          />
        )}
        {component.component_type === 'PAYMENT' && (
          <Payment
            key={component.id}
            name={component.name}
            placeholder={component.label}
            integration={component.integration}
            selectedProduct={selectedProduct}
            selectedAddress={selectedAddress}
            backgroundColor={getFormAttributeValue('background_color')}
            setPaymentDetails={setPaymentDetails}
            onSubmitForm={onSubmitForm}
          />
        )} */}
        <div
          className="absolute flex items-center justify-center component-resize-icon"
          style={{
            right: 0,
            bottom: 0,
            zIndex: 3,
            width: `${(20 * zoom) / 100}px`,
            height: `${(20 * zoom) / 100}px`,
            cursor: 'se-resize',
          }}
          onMouseDown={startResize}
          onClick={e => e.stopPropagation()}>
          <ArrowRightIcon width={(10 * zoom) / 100} height={(10 * zoom) / 100} />
        </div>
      </div>
      <div className="flex-column items-center justify-center mt-4 component-hover-icon widget-comp-hover-icon">
        <div
          className="pxy-1 flex items-center justify-center radius-1 delete-widget cursor"
          onClick={e => {
            e.stopPropagation();
            updateWidget({
              ...widget,
              panelId: selectedPage.id,
              components: widget.components.filter(c => c.id !== component.id),
            });
            selectComponent(null);
          }}>
          <DeleteIcon />
        </div>
        <div className="flex items-center justify-center relative add-widget my-1">
          <div className="flex items-center justify-center">
            <PlusIcon
              className="cursor"
              onClick={() =>
                updateWidget({
                  ...widget,
                  panelId: selectedPage.id,
                  components: [
                    ...widget.components,
                    { ...component, id: null, x: component.x + 10, y: component.y + 10 },
                  ],
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetComponent;

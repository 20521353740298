import moment from 'moment';

export const timeMeridiemOptions = [
  { name: 'AM', value: 'AM' },
  { name: 'PM', value: 'PM' },
];

export const weekDayOptions = [
  { name: 'Monday', value: 1 },
  { name: 'Tuesday', value: 2 },
  { name: 'Wednesday', value: 3 },
  { name: 'Thursday', value: 4 },
  { name: 'Friday', value: 5 },
  { name: 'Saturday', value: 6 },
  { name: 'Sunday', value: 7 },
];

export const monthOptions = [
  { label: 'January', name: 'January', value: 1 },
  { label: 'February', name: 'February', value: 2 },
  { label: 'March', name: 'March', value: 3 },
  { label: 'April', name: 'April', value: 4 },
  { label: 'May', name: 'May', value: 5 },
  { label: 'June', name: 'June', value: 6 },
  { label: 'July', name: 'July', value: 7 },
  { label: 'August', name: 'August', value: 8 },
  { label: 'September', name: 'September', value: 9 },
  { label: 'October', name: 'October', value: 10 },
  { label: 'November', name: 'November', value: 11 },
  { label: 'December', name: 'December', value: 12 },
];

export const weekOfMonthOptions = [
  { name: 'First', value: 1 },
  { name: 'Second', value: 2 },
  { name: 'Third', value: 3 },
  { name: 'Fourth', value: 4 },
  { name: 'Fifth', value: 5 },
];

export const recurrenceIntervalOptions = [
  { name: 'Daily', value: 'DAILY' },
  { name: 'Weekly', value: 'WEEKLY' },
  { name: 'Monthly', value: 'MONTHLY' },
  { name: 'Yearly', value: 'YEARLY' },
];

export const fontStyleOptions = [
  {
    value: 'THIN',
    name: 'Thin',
  },
  {
    value: 'EXTRALIGHT',
    name: 'Extralight',
  },
  {
    value: 'LIGHT',
    name: 'Light',
  },
  {
    value: 'REGULAR',
    name: 'Regular',
  },
  {
    value: 'MEDIUM',
    name: 'Medium',
  },
  {
    value: 'SEMIBOLD',
    name: 'Semibold',
  },
  {
    value: 'BOLD',
    name: 'Bold',
  },
  {
    value: 'EXTRABOLD',
    name: 'Extrabold',
  },
  {
    value: 'BLACK',
    name: 'Black',
  },
  {
    value: 'THINITALIC',
    name: 'Thinitalic',
  },
  {
    value: 'EXTRALIGHTITALIC',
    name: 'Extralightitalic',
  },
  {
    value: 'LIGHTITALIC',
    name: 'Lightitalic',
  },
  {
    value: 'ITALIC',
    name: 'Italic',
  },
  {
    value: 'MEDIUMITALIC',
    name: 'Mediumitalic',
  },
  {
    value: 'SEMIBOLDITALIC',
    name: 'Semibolditalic',
  },
  {
    value: 'BOLDITALIC',
    name: 'Bolditalic',
  },
  {
    value: 'EXTRABOLDITALIC',
    name: 'Extrabolditalic',
  },
  {
    value: 'BLACKITALIC',
    name: 'Blackitalic',
  },
];

export const timezoneData = [
  ...Intl.supportedValuesOf('timeZone')
    .filter(t => t.includes('Europe') || t.includes('America'))
    .map(t => ({ name: t, value: t })),
];

export const stateOptions = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
    value: 'Alabama',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
    value: 'Alaska',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
    value: 'Arizona',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
    value: 'Arkansas',
  },
  {
    name: 'California',
    abbreviation: 'CA',
    value: 'California',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
    value: 'Colorado',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
    value: 'Connecticut',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
    value: 'Delaware',
  },
  {
    name: 'District of Columbia',
    abbreviation: 'DC',
    value: 'District of Columbia',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
    value: 'Florida',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
    value: 'Georgia',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
    value: 'Hawaii',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
    value: 'Idaho',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
    value: 'Illinois',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
    value: 'Indiana',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
    value: 'Iowa',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
    value: 'Kansas',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
    value: 'Kentucky',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
    value: 'Louisiana',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
    value: 'Maine',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
    value: 'Maryland',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
    value: 'Massachusetts',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
    value: 'Michigan',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
    value: 'Minnesota',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
    value: 'Mississippi',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
    value: 'Missouri',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
    value: 'Montana',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
    value: 'Nebraska',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
    value: 'Nevada',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
    value: 'New Hampshire',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
    value: 'New Jersey',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
    value: 'New Mexico',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
    value: 'New York',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
    value: 'North Carolina',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
    value: 'North Dakota',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
    value: 'Ohio',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
    value: 'Oklahoma',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
    value: 'Oregon',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
    value: 'Pennsylvania',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
    value: 'Puerto Rico',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
    value: 'Rhode Island',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
    value: 'South Carolina',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
    value: 'South Dakota',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
    value: 'Tennessee',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
    value: 'Texas',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
    value: 'Utah',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
    value: 'Vermont',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
    value: 'Virginia',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
    value: 'Washington',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
    value: 'West Virginia',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
    value: 'Wisconsin',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
    value: 'Wyoming',
  },
];

export const contactedClientOption = [
  { name: 'Yes', value: true },
  { name: 'No', value: false },
];

export const entrantCancelContactType = [
  { name: 'Email', value: 'EMAIL' },
  { name: 'Phone', value: 'PHONE' },
  { name: 'Text', value: 'SMS' },
  { name: 'Dispute', value: 'DISPUTE' },
];

export const formComponentTypes = [
  { name: 'Alphanumeric', label: 'Alphanumeric', value: 'ALPHANUMERIC' },
  { name: 'Alpha', label: 'Alpha', value: 'ALPHA' },
  { name: 'Numeric', label: 'Numeric', value: 'NUMERIC' },
  // { name: 'Phone', label: 'Phone', value: 'PHONE' },
  { name: 'Signature', label: 'Signature', value: 'SIGNATURE' },
  { name: 'Date', label: 'Date', value: 'DATE' },
  // { name: 'Date of Birth', label: 'Date of Birth', value: 'DOB' },
  // { name: 'Address', label: 'Address', value: 'ADDRESS' },
  // { name: 'Chooser', label: 'Chooser', value: 'CHOOSER' },
  { name: 'Header', label: 'Header', value: 'HEADER' },
  { name: 'Textarea', label: 'Textarea', value: 'TEXTAREA' },
  // { name: 'Payment', label: 'Payment', value: 'PAYMENT' },
  { name: 'Image', label: 'Image', value: 'IMAGE' },
  { name: 'PDF pages', label: 'PDF pages', value: 'PDF_PAGES' },
  // { name: 'Product', label: 'Product', value: 'PRODUCT' },
  // { name: 'Multichoice', label: 'Multichoice', value: 'MULTICHOICE' },
];

export const dateOfBirthComponentTypes = [
  { name: 'Date picker', label: 'Date picker', value: 'DATEPICKER' },
  { name: 'Day,Month,Year', label: 'Day,Month,Year', value: 'DAYMONTHYEAR' },
];

export const dateRangeOption = [
  {
    name: 'Today',
    label: 'Today',
    value: 'TODAY',
    startDate: moment().set('hour', 0).set('minute', 0).set('second', 0).unix(),
    endDate: moment().set('hour', 24).set('minute', 0).set('second', 0).unix(),
  },
  {
    name: 'Current Month',
    label: 'Current Month',
    value: 'CURRENT_MONTH',
    startDate: moment().startOf('month').set('hour', 0).set('minute', 0).set('second', 0).unix(),
    endDate: moment().endOf('month').set('hour', 23).set('minute', 59).set('second', 59).unix(),
  },
  {
    name: 'Last 7 days',
    label: 'Last 7 days',
    value: 'LAST_7_DAYS',
    startDate: moment().subtract(7, 'd').set('hour', 0).set('minute', 0).set('second', 0).unix(),
    endDate: moment().unix(),
  },
  {
    name: 'Last month',
    label: 'Last month',
    value: 'LAST_MONTH',
    startDate: moment().add(-1, 'months').startOf('month').set('hour', 0).set('minute', 0).set('second', 0).unix(),
    endDate: moment().add(-1, 'months').endOf('month').set('hour', 23).set('minute', 59).set('second', 59).unix(),
  },
  {
    name: 'All',
    label: 'All',
    value: 'ALL',
    startDate: moment().set('hour', 0).set('minute', 0).set('second', 0).unix(),
    endDate: moment().unix(),
  },
  {
    name: 'Custom',
    label: 'Custom',
    value: 'CUSTOM',
    startDate: moment().add(-1, 'months').set('hour', 0).set('minute', 0).set('second', 0).startOf('day').unix(),
    endDate: moment().endOf('day').unix(),
  },
];

export const dayOptions = Array(31)
  .fill()
  .map((_, idx) => 1 + idx)
  .map(value => ({ label: value.toString(), value }));

export const yearOptions = Array(2005 - 1900)
  .fill()
  .map((_, idx) => 2023 - idx)
  .map(value => ({ label: value.toString(), value }));
